<template>
<v-container fluid>
    <QPointsTab :activeTab="'config'" />
    <v-form ref="pform">
        <div class="titles mt-5">Configuration</div>
        <v-card outlined  class="mb-4">
            <v-row class="pa-5">
                <v-col cols="12" sm="6" md="2">
                  <label>Minimum Spend*</label>
                  <v-text-field
                      v-model="config.min_spend"
                      outlined
                     :rules="[(v) => !!v || 'Minimum Spend Required']"
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      :prefix="currencyCode"
                      placeholder="ex. 200"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <label>Percentage of Spend*</label>
                  <v-text-field
                      v-model="config.pct_spend"
                      outlined
                      :rules="[(v) => !!v || 'Percentage Required']"
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      suffix="%"
                      placeholder="ex. 5"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <label>Maximum QPoints*</label>
                  <v-text-field
                      v-model="config.max_point"
                      outlined
                      :rules="[(v) => !!v || 'Maximum QPoint Required']"
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      placeholder="ex. 1000 per transaction"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <label>Point Value*</label>
                  <v-text-field
                      v-model="config.point_value"
                      outlined
                      :rules="[(v) => !!v || 'Point Value Required']"
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      :prefix="currencyCode"
                      placeholder="ex. 1"
                      readonly
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <label>Point Expiry Days*</label>
                  <v-text-field
                      v-model="config.expiry_days"
                      outlined
                      :rules="[(v) => !!v || 'Expiry Days Required']"
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      placeholder="ex. 365"
                      suffix="Days"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                    <label for="">Status</label>
                    <v-select
                        v-model="config.status_id"
                        :items="[{ id: 1, name: 'Active' }, { id: 2, name: 'Inactive' }]"
                        item-value="id"
                        item-text="name"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        class="q-autocomplete shadow-0"
                        hide-details="auto"
                        dense
                    ></v-select>
                    </v-col>
            </v-row>
        </v-card>
        <div style="float: right">
        <v-btn class="white--text blue-color" @click="saveConfiguration($event)" >Update</v-btn>
        </div>
    </v-form>
</v-container>
</template>

<script>
import QPointsTab from "@/components/QPoints/QPointsTab.vue";
export default {
    components: { QPointsTab },
    data() {
        return {
            config: {},
        };
    },
    mounted() {
        this.getConfiguration();
    },
    methods: {
        getConfiguration() {
            this.showLoader();
            this.$http.get("venues/qpoints/config").then((response) => {
                if (response.status == 200 && response.data.status == true) {
                    this.hideLoader();
                    if(response.data.data){
                        this.config =  response.data.data;
                    }else{
                         this.config = {point_value : 0.1};
                    }
                }
            }).catch((error) => {
                this.errorChecker(error);
            });
        },
        saveConfiguration() {
            if (!this.$refs.pform.validate()) {
                this.showError("Fill all required fields");
                return;
            }
            let formData = new FormData();
            formData.append(`min_spend`, this.config.min_spend);
            formData.append(`pct_spend`, this.config.pct_spend);
            formData.append(`max_point`, this.config.max_point);
            formData.append(`point_value`, this.config.point_value);
            formData.append(`expiry_days`, this.config.expiry_days);
            formData.append(`status_id`, this.config.status_id);

            this.showLoader();
            this.$http({
                method: "post",
                data: formData,
                url: "venues/qpoints/config",
                headers: { "Content-Type": "multipart/form-data; boundary=${form._boundary}",},
            }).then((response) => {
                if (response.status == 200) {
                    this.hideLoader();
                    this.showSuccess(response.data.message);
                    this.getConfiguration()
                }
            }).catch((error) => {
                this.errorChecker(error);
            });
        },
    },
};
</script>